import React from "react";
import {
  ActionButton,
  ButtonSkip,
  ButtonDownload,
  ModalComponentWrapper,
  TitleUpdate,
} from "./styled";
import { useTranslation } from "react-i18next";


const ModalConfirmUpdate = ({ onOk, onCancel, visibleModal}) => {
  const [t] = useTranslation('student');
  return (
    <ModalComponentWrapper
      open={visibleModal}
      onOk={onOk}
      onCancel={onCancel}
      centered
      title={t("update_course")}
      className='modal__import'
    >
      <TitleUpdate>{t("title_update_course")}</TitleUpdate>
      <TitleUpdate>{t("title_update_course_1")}</TitleUpdate>
      <TitleUpdate>{t("title_update_course_2")}</TitleUpdate>
      <ActionButton>
        <ButtonDownload onClick={onOk}>{t('update')}</ButtonDownload>
        <ButtonSkip onClick={onCancel}>{t('cancel')}</ButtonSkip>
      </ActionButton>
    </ModalComponentWrapper>
  );
};

export default ModalConfirmUpdate;
